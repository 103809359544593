<ng-container *ngIf="hasData">
  <ng-container *ngIf="unsavedChanges && sectionChanges.length > 0">
    <app-menu-bar>
      <span class="unsaved-changed">Unsaved Changes Made</span>
      <button mat-flat-button color="primary" class="save-button" (click)="saveGuide()">Save Changes</button>
    </app-menu-bar>
  </ng-container>

  <main class="main-content">
    <ng-container *loadingSpinner="!guideData || isSaving">
      <div>
        <h2 class="edit-page-header">Edit Onboarding guides</h2>
      </div>
      <mat-accordion>
        <div cdkDropList (cdkDropListDropped)="dropped($event)">
          <ng-container *ngFor="let section of guideData.sections; let i = index">
            <expansionPanel
              [currentSectionId]="section.guideSectionId"
              [sectionTitle]="section.title"
              [content]="section.content"
              (unsavedChanges)="changesMade($event)"
            ></expansionPanel>
          </ng-container>
        </div>
      </mat-accordion>
      <div class="edit-page-footer">
        <button mat-fab color="primary" class="new-section-icon" (click)="addNewSection()">
          <mat-icon fontIcon="add"></mat-icon>
        </button>
      </div>
    </ng-container>
  </main>
</ng-container>
<ng-container *ngIf="!hasData">
  <main class="main-content">
    <h3>Guide data not found for GuideId: {{guideId}}</h3>
  </main>
</ng-container>