<div cdkDrag>
  <mat-expansion-panel
    (opened)="panelOpenState = true"
    (closed)="panelOpenState = false"
    [ngClass]="componentChangesClass"
    class="ob-expansion"
  >
    <mat-expansion-panel-header>
      <div class="drag-handle" cdkDragHandle>
        <mat-icon aria-hidden="false" aria-label="Drag Handle Icon" fontIcon="drag_handle"></mat-icon>
      </div>
      <mat-panel-title>{{ rawOriginalTitle }}</mat-panel-title>
    </mat-expansion-panel-header>
    <form class="section-form" [formGroup]="sectionForm">
      <mat-form-field class="section-input" appearance="outline">
        <mat-label>Section Name</mat-label>
        <input
          matInput
          id="section-name"
          name="section-name"
          type="text"
          class="large"
          [required]="true"
          formControlName="sectionTitleControl"
          placeholder="Enter Section Title"
        />
      </mat-form-field>

      <mat-form-field class="section-input content" appearance="outline" floatLabel="always">
        <mat-label>Content</mat-label>
        <textarea
          matInput
          placeholder="Angular Material UI Hack for mat-label"
          required
          style="display: none"
        ></textarea>
        <div *ngIf="showEditor">
          
          <quill-editor
            (onEditorCreated)="onCreated($event)"
            class="content-editor"
            name="editor"
            formControlName="sectionContentControl"
            [readOnly]="disableSection"
          >
          </quill-editor>
          <!-- <quill-table-options (updateParent)="handleUpdateOptions($event)" [editorInstance]="quillInstance" [showOptions]="showTableOptions"></quill-table-options> -->
        </div>
        
        <div *ngIf="!showEditor">
          <div [innerHtml]="convertedContent"></div>
        </div>
      </mat-form-field>
      <mat-action-row class="action-row">
        <button mat-flat-button color="primary" (click)="convertToSingleColumnFormat()" *ngIf="!showEditor">Convert to single column format (enable editing)</button>
        <button mat-flat-button color="primary" (click)="convertToTableFormat()" *ngIf="showEditor">Convert to 2-column format</button>
        <button mat-stroked-button color="warn" (click)="discardChanges()" *ngIf="showDiscardChangesButton()">Discard Changes</button>
        <button mat-stroked-button color="warn" (click)="delete()">{{ disableSection ? 'Undo' : 'Remove' }}</button>
      </mat-action-row>
    </form>
  </mat-expansion-panel>
</div>
