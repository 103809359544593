import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { NotificationService, toastPayload } from '../../shared/services/notification.service';
import { MatDialog } from '@angular/material/dialog';
import { IndividualConfig } from 'ngx-toastr';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Subject, takeUntil } from 'rxjs';
import { FormControl, FormGroup } from '@angular/forms';
import { UnsavedSectionChanges } from 'src/app/models/unsaved-section-changes';
import { ChangeType } from '../../models/changeType';
import { QuillEditor, ToolbarModule } from './quillTypes';
import Quill from 'quill';

@Component({
  selector: 'expansionPanel',
  templateUrl: './expansionPanel.component.html',
  styleUrls: ['./expansionPanel.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class ExpansionPanelComponent implements OnInit {
  @Input()
  currentSectionId: number;
  @Input()
  sectionTitle: string;
  @Input()
  rawOriginalTitle: string;
  @Input()
  content: string;
  @Input()
  rawOriginalContent: string;
  @Output()
  unsavedChanges: EventEmitter<UnsavedSectionChanges> = new EventEmitter<UnsavedSectionChanges>();
  showTableOptions = false;
  quillInstance: QuillEditor;

  sectionForm: FormGroup;
  private toast!: toastPayload;
  panelOpenState = true;
  destroyed = new Subject<void>();
  currentScreenSize: string;
  originalContent: string;
  disableSection: boolean = false;
  //rawOriginalContent: string;
  //rawOriginalTitle: string;
  componentChangesClass: string = 'background-color: rgba(255, 255, 255)';
  html: string;
  showEditor: boolean = true;
  convertedContent: string = null;
  
  constructor(public dialog: MatDialog, private notifier: NotificationService, breakpointObserver: BreakpointObserver) {
    breakpointObserver
      .observe([Breakpoints.XSmall, Breakpoints.Small, Breakpoints.Medium, Breakpoints.Large, Breakpoints.XLarge])
      .pipe(takeUntil(this.destroyed))
      .subscribe((result) => {
        for (const query of Object.keys(result.breakpoints)) {
          if (result.breakpoints[query]) {
            this.currentScreenSize = this.displayNameMap.get(query) ?? 'Unknown';
          }
        }
      });
  }

  ngOnInit(): void {
    this.sectionForm = new FormGroup({
      sectionTitleControl: new FormControl({ value: this.sectionTitle, disabled: this.disableSection }),
      sectionContentControl: new FormControl({ value: this.content, disabled: this.disableSection })
    });

    //Get a baseline string to compare to for this component, if any changes are made then it will trigger the save button display
    this.originalContent = this.sectionForm.get('sectionContentControl').value.split('\n').join('').replace(/\s+/g, '');

    // Add a watch to the title control for changes made by the user
    this.sectionForm.get('sectionTitleControl').valueChanges.subscribe((selectedValue: any) => {
      this.updateSavedChanges(false);
    });

    // The Editor is a bit weird when data is first loaded, it will trigger the onchange event even if there are no changes.
    // Extra logic has been added to help prevent this.
    this.sectionForm.get('sectionContentControl').valueChanges.subscribe((selectedValue: any) => {
      // Trim the selected value so that we ensure comparing only what matters.
      const trimmedValue = selectedValue.split('\n').join('').replace(/\s+/g, '');
      // Only update the saved changes if the original value is different than the current value.
      if (trimmedValue !== this.originalContent) {
        this.updateSavedChanges(false);
      }
    });

    this.rawOriginalContent = this.content;
    this.rawOriginalTitle = this.sectionTitle;

    if (this.rawOriginalContent.includes('<table>')) {
      this.convertedContent = this.rawOriginalContent;
      this.showEditor = false;
    }
  }

  onCreated(editor: Quill) {
    this.quillInstance = editor as QuillEditor;
    const toolbar = this.quillInstance.getModule<ToolbarModule>('toolbar');

    toolbar.addHandler(
      'table',
      (() => {
        this.showTableOptions = false;
      }).bind(this)
    );
  }

  handleUpdateOptions(value: boolean) {
    this.showTableOptions = value;
  }

  updateSavedChanges(remove: boolean, undo: boolean = false): void {
    this.disableSection = remove;

    this.unsavedChanges.emit({
      guideSectionId: this.currentSectionId,
      title: this.sectionForm.get('sectionTitleControl').value,
      content: this.sectionForm.get('sectionContentControl').value,
      remove: remove,
      undo: undo
    });
    this.componentChangesClass = remove ? ChangeType.remove : ChangeType.update;
  }

  delete(): void {
    if (this.disableSection) {
      this.sectionForm.get('sectionTitleControl').enable();
      this.updateSavedChanges(false, true);
    } else {
      this.sectionForm.get('sectionTitleControl').disable();
      this.updateSavedChanges(true);
    }
  }

  discardChanges(): void {
    this.sectionForm.get('sectionContentControl').setValue(this.rawOriginalContent);
    this.sectionForm.get('sectionTitleControl').setValue(this.rawOriginalTitle);
    this.componentChangesClass = ChangeType.save;

    if (this.rawOriginalContent.includes('<table>')) {
      this.convertedContent = this.rawOriginalContent;
      this.showEditor = false;
    } else {
      this.convertedContent = null;
      this.showEditor = true
    }

    this.updateSavedChanges(false, true);
  }

  showDiscardChangesButton(): boolean {
    return this.rawOriginalContent !== this.sectionForm.get('sectionContentControl').value || this.rawOriginalTitle !== this.sectionForm.get('sectionTitleControl').value;
  }

  showToastMessage(type: string, message: string) {
    this.toast = {
      message: message,
      title: '',
      type: type,
      config: {
        timeOut: 2500,
        positionClass: 'toast-bottom-right',
        closeButton: true
      } as IndividualConfig
    };
    this.notifier.showToast(this.toast);
  }

  displayNameMap = new Map([
    [Breakpoints.XSmall, 'XSmall'],
    [Breakpoints.Small, 'Small'],
    [Breakpoints.Medium, 'Medium'],
    [Breakpoints.Large, 'Large'],
    [Breakpoints.XLarge, 'XLarge']
  ]);

  convertToTableFormat() {
    var existingContent = this.sectionForm.get('sectionContentControl').value;
    
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = existingContent;

    var images = tempDiv.querySelectorAll('img');
    var imageContent = [];
    images.forEach((image) => {
      imageContent.push(image.outerHTML);
      image.remove();
    });

    var imgHtml = '';

    if (images.length > 0) {
      imgHtml = imageContent[0];
    }

    var newContent = `<table><tr><td width="66%" valign="top">${tempDiv.innerHTML}</td><td width="33%">${imgHtml}</td></tr></table>`;
    this.sectionForm.get('sectionContentControl').setValue(newContent);
    this.convertedContent = newContent;
    
    this.showEditor = false;
  }

  convertToSingleColumnFormat() {
    var existingContent = this.sectionForm.get('sectionContentControl').value;
    
    var tempDiv = document.createElement('div');
    tempDiv.innerHTML = existingContent;

    var table = tempDiv.querySelectorAll('table');

    if (table.length > 0) {
      var images = tempDiv.querySelectorAll('img');
      var imageContent = [];

      images.forEach((image) => {
        imageContent.push(image.outerHTML);
        image.remove();
      })

      var imgHtml = '';

      if (images.length > 0) {
        imgHtml = imageContent[0];
      }

      var tableCells = tempDiv.querySelectorAll('td');
      var textContent = '';

      tableCells.forEach((cell) => {
        textContent += cell.innerHTML;
      });

      var newContent = `${imgHtml}<br />${textContent}`;
      this.sectionForm.get('sectionContentControl').setValue(newContent);
      this.convertedContent = null;

      this.showEditor = true;
    }
  }

  ngOnDestroy() {
    this.destroyed.next();
    this.destroyed.complete();
  }
}
